<template lang="html">
  <div class="slide-ads">
    <!-- <h2>Featured Shipyard</h2> -->
    <hooper class="desktop-only" :settings="hooperSettings">

      <slide>
        <div class="vendor">
          <a href="https://tadakara.id/">
             <img src="/images/ads_tts_profesea.jpg" alt="Tadakara">
          </a>
        </div>
      </slide>

      <slide>
        <div class="vendor">
          <a href="https://semangatmaritim.id/">
             <img src="/images/ads_semangat_profesea.png" alt="Semangat maritime">
          </a>
        </div>
      </slide>

      <slide>
        <div class="vendor">
          <a href="https://jagadmandirimarine.com/">
             <img src="/images/jagad-maritime.png" alt="Jagad maritime">
          </a>
        </div>
      </slide>

      <slide>
          <div class="vendor">
            <a href="https://www.docking.id/id/vendor/profile/allied-eparts-pte-ltd">
            <img src="/images/buynav_banner.png " alt="Jotun Indonesia">
            </a>
          </div>
      </slide>

      <slide>
        <div class="vendor">
          <a href="https://www.docking.id/id/vendor/profile/desmi-singapore-pte-ltd-indonesia-representative-office">
            <img src="/images/desmi_logo_home.jpg" alt="DESMI LOGO">
          </a>
        </div>
      </slide>

      <slide>
        <div class="vendor">
          <a href="https://www.docking.id/id/vendor/profile/pt-samudera-dharma-kalingga">
            <img src="/images/SDK_BANNER2.png" alt="Samudera Dharma kalingga Logo">
          </a>
        </div>
      </slide>

      <slide>
        <div class="vendor">
          <a href="https://www.docking.id/id/galangan/profile/pt-caputra-mitra-sejati">
            <img src="/images/caputra_shipyard_banner.jpg" alt="Caputra Shipyard">
          </a>
        </div>
      </slide>

      <slide>
        <div class="vendor">
          <a href="https://www.docking.id/id/vendor/profile/alamarin-jet">
            <img src="/images/almarin.png " alt="alamarin jet">
          </a>
        </div>
      </slide>

      <slide>
          <div class="vendor">
            <a href="https://www.docking.id/id/vendor/profile/pt-jotun-indonesia">
            <img src="/images/jotun.png " alt="Jotun Indonesia">
            </a>
          </div>
      </slide>

      <!-- <slide>
        <div class="vendor">
          <a href="https://www.docking.id/id/vendor/profile/pt-pcm-kabel-indonesia">
            <img src="/images/pcm_logo_home.jpg" alt="PCM LOGO">
          </a>
        </div>
      </slide> -->

      <slide>
        <div class="vendor">
          <a href="https://www.docking.id/id/vendor/profile/pt-arthapusaka-mitra-sakti">
            <img src="/images/mobilgard.png " alt="Mobil Gard Banjarmasin">
          </a>
        </div>
      </slide>

      <hooper-navigation slot="hooper-addons"></hooper-navigation>
    </hooper>

    <hooper class="mobile-only" :settings="mobilehooperSettings">

       <slide>
        <div class="vendor">
          <a href="https://inamarine-exhibition.net/">
            <img src="/images/inamarin-2024.jpg" alt="Inamarine Agustus 2024">
          </a>
        </div>
      </slide>
      
      <slide>
          <div class="vendor">
            <a href="https://www.docking.id/id/vendor/profile/allied-eparts-pte-ltd">
            <img src="/images/buynav_banner.png " alt="Jotun Indonesia">
            </a>
          </div>
      </slide>

      <slide>
        <div class="vendor">
          <a href="https://www.docking.id/id/vendor/profile/desmi-singapore-pte-ltd-indonesia-representative-office">
            <img src="/images/desmi_logo_home.jpg" alt="DESMI LOGO">
          </a>
        </div>
      </slide>

      <slide>
        <div class="vendor">
          <a href="https://www.docking.id/id/vendor/profile/pt-samudera-dharma-kalingga">
            <img src="/images/SDK_BANNER2.png" alt="Samudera Dharma kalingga Logo">
          </a>
        </div>
      </slide>

      <slide>
        <div class="vendor">
          <a href="https://www.docking.id/id/galangan/profile/pt-caputra-mitra-sejati">
            <img src="/images/caputra_shipyard_banner.jpg" alt="Caputra Shipyard">
          </a>
        </div>
      </slide>

      <slide>
        <div class="vendor">
          <a href="https://www.docking.id/id/vendor/profile/alamarin-jet">
            <img src="/images/almarin.png " alt="alamarin jet">
          </a>
        </div>
      </slide>

      <slide>
          <div class="vendor">
            <a href="https://www.docking.id/id/vendor/profile/pt-jotun-indonesia">
            <img src="/images/jotun.png " alt="Jotun Indonesia">
            </a>
          </div>
      </slide>

      <slide>
        <div class="vendor">
          <a href="https://www.docking.id/id/vendor/profile/pt-pcm-kabel-indonesia">
            <img src="/images/pcm_logo_home.jpg" alt="PCM LOGO">
          </a>
        </div>
      </slide>

      <slide>
        <div class="vendor">
          <a href="https://www.docking.id/id/vendor/profile/pt-arthapusaka-mitra-sakti">
            <img src="/images/mobilgard.png " alt="Mobil Gard Banjarmasin">
          </a>
        </div>
      </slide>

      <!-- <slide>
        <div class="vendor">
          <a href="https://www.docking.id/id/galangan/profile/pt-yasa-wahana-tirta-samudera">
            <img src="/images/samudera_indonesia.png " alt="Samudera Indonesia">
          </a>
        </div>
      </slide> -->

      <hooper-navigation slot="hooper-addons"></hooper-navigation>
    </hooper>

  </div>
</template>

<script>

import { Hooper, Slide , Navigation as HooperNavigation } from 'hooper';
import 'hooper/dist/hooper.css';

export default {
  components : {
    Hooper,
		Slide,
		HooperNavigation
  },
  data () {
    return {
      hooperSettings: {
        itemsToShow: 5,
        autoPlay : true,
        infiniteScroll : true,
        transition : 1000,
        playSpeed : 4500,
        breakpoints: {
          320: {
            centerMode: true,
            itemsToShow: 1,
            autoPlay : true
          },
          720: {
            itemsToShow: 5
          }
        }
      },
      mobilehooperSettings: {
        itemsToShow: 1,
        autoPlay : true,
        infiniteScroll : true,
        touchDrag : true,
        transition : 1000,
        playSpeed : 2500,
        breakpoints: {
          320: {
            centerMode: true,
            itemsToShow: 1,
            autoPlay : true
          },
          720: {
            itemsToShow: 1
          }
        }
      }
    }
  }
}
</script>

<style lang="scss">
	@import '~/assets/scss/_mixins.scss';
	@import './style.scss';
</style>
